import { graphql, StaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import Helmet from "react-helmet";

interface SEOProps {
    description?: string;
    lang?: string;
}
function SEO({ description, lang }: SEOProps) {
    const keywords: string[] = [
        "bike to work",
        "bike2work",
        "cycling",
        "bike it",
        "bike challenge",
        "cycling challenge",
        "bike2work",
        "employer branding",
        "engagement",
        "well-being",
        "Endomondo",
        "Strava",
    ];

    return (
        <StaticQuery
            query={detailsQuery}
            render={data => {
                return (
                    <Helmet
                        htmlAttributes={{
                            lang,
                        }}
                        title={data.site.siteMetadata.title}
                        titleTemplate={`%s | ${data.site.siteMetadata.title}`}
                        meta={[
                            {
                                name: `description`,
                                content:
                                    "Bike IT is a global cycling challenge between IT companies. Employees bike to work or casually to earn points in an engaging mobile game. It’s based on fun and engagement, without sporty race. It helps to stay healthy by having fun!",
                            },
                            {
                                property: `og:title`,
                                content: "Global Cycling Challenge for IT industry",
                            },
                            {
                                property: `og:description`,
                                content:
                                    "Bike IT is a global cycling challenge between IT companies. Employees bike to work or casually to earn points in an engaging mobile game. It’s based on fun and engagement, without sporty race. It helps to stay healthy by having fun!",
                            },
                            {
                                property: `og:type`,
                                content: `website`,
                            },
                        ]
                            .concat(
                                keywords.length > 0
                                    ? {
                                          name: `keywords`,
                                          content: keywords.join(`, `),
                                      }
                                    : [],
                            )
                            .concat([
                                {
                                    property: "og:image",
                                    content:
                                        "https://activyproduction.blob.core.windows.net/assets/bikeit_opengraph.jpg",
                                },
                                {
                                    property: "og:url",
                                    content: "http://www.bikeitchallenge.com",
                                },
                                {
                                    property: "og:site_name",
                                    content: "Bike IT Challenge",
                                },
                            ])}
                    />
                );
            }}
        />
    );
}

SEO.defaultProps = {
    lang: "pl",
    meta: [],
    keywords: [],
};

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.array,
    keywords: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string.isRequired,
};

export default SEO;

const detailsQuery = graphql`
    query DefaultSEOQuery {
        site {
            siteMetadata {
                title
                description
                author
            }
        }
    }
`;
