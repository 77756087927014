import React, { PropsWithChildren, useEffect } from "react";

const scrollToHash = () => {
    if (window.location.hash) {
        const element = document.getElementById(window.location.hash.replace("#", ""));
        if (element) {
            console.warn("scroling to hash");
            element.scrollIntoView({ behavior: "smooth" });
        }
    }
};

export default function ScrollToHash({ children }: PropsWithChildren<{}>) {
    useEffect(() => {
        window.addEventListener("hashchange", scrollToHash);
        return () => window.removeEventListener("hashchange", scrollToHash);
    }, []);

    useEffect(() => {
        scrollToHash();
    }, []);
    return <>{children}</>;
}
