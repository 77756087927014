import React from "react";
import styled from "styled-components";

const StyledA = styled.a`
    display: inline-block;
`;
const StyledImg = styled.img`
    object-fit: contain;

    height: 100%;
`;

interface BadgeProps {
    link: string;
    image: string;

    className?: string;
}

export function Badge(props: BadgeProps) {
    return (
        <StyledA href={props.link} className={props.className} target="_blank">
            <StyledImg src={props.image} />
        </StyledA>
    );
}
