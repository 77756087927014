import useContent, { ContentContext } from "hooks/use-content";
import PropTypes from "prop-types";
import React from "react";
import styled, { ThemeProvider } from "styled-components";
import Global from "styles/global";
import { theme } from "styles/theme";
import Footer from "./footer";
import Header from "./header";
import ScrollToHash from "./scroll-to-hash";
import Cookie from "./cookie";

const StyledFooter = styled(Footer)``;

const Root = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    & > main {
        flex: 1 1 auto;
    }

    & > ${StyledFooter} {
        flex: 0 0 auto;
    }
`;

type PropsOfMain = JSX.IntrinsicElements["main"];
export interface LayoutProps extends PropsOfMain {}
function Layout({ children, ...mainProps }) {
    const content = useContent();
    return (
        <ThemeProvider theme={theme}>
            <ContentContext.Provider value={content}>
                <ScrollToHash />
                <Root>
                    <Global />
                    <Header />
                    <main {...mainProps}>{children}</main>
                    <StyledFooter />
                    <Cookie />
                </Root>
            </ContentContext.Provider>
        </ThemeProvider>
    );
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
