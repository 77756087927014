import { Content } from "declarations/content";
import { withPrefix } from "gatsby";
import { createContext, useEffect, useState } from "react";
import defaultContent from "services/default-content";

// declare var contentPl: Content;
// export default function useContent(): Content {
//     return contentPl;
// }

function useContent() {
    const [content, setContent] = useState<Content>(defaultContent);
    const locale = "pl";

    useEffect(() => {
        fetch(withPrefix(`/content/content-${locale}.json`))
            .then(e => e.json())
            .then(setContent)
            .catch(e => console.error(`Error while fetching content for locale ${locale}.`, e));
    }, []);

    return content;
}

export const ContentContext = createContext<Content>(defaultContent);

useContent.provider = ContentContext.Provider;

export default useContent;
