import { Content } from "declarations/content";

const defaultContent: Content = {
    header: {
        nav: [
            {
                text: "How it works",
                to: "/#how-it-works",
            },
            {
                text: "Activy App",
                to: "/#about-app",
            },
            {
                text: "How to start",
                to: "/#get-started",
            },
            {
                text: "Sign up a team",
                emphasized: true,
                to: "/purchase",
            },
        ],
    },
    footer: {
        socialMediaLinks: [
            {
                key: "fb",
                icon: "/images/footer/logo-facebook.svg",
                link: "https://www.facebook.com/activyapp",
            },
            {
                key: "ig",
                icon: "/images/footer/logo-instagram.svg",
                link: "https://www.instagram.com/activy_bike2work/",
            },
            {
                key: "in",
                icon: "/images/footer/logo-linkedin.svg",
                link: "https://www.linkedin.com/company/18028340",
            },
        ],
        demoButton: {
            text: "Sign up a team",
            link: "/purchase",
        },
        text: "Activy for better health, clean environment and sustainable cities.",
        contact: "contact@bikeitchallenge.com",
        address: "Activy Ltd.\n\naccelerated in Residency of Google for Startups",
    },
    home: {
        section00: {
            text: "Global Cycling Challenge for IT industry",
            button: {
                text: "Let’s start",
                link: "#get-started",
            },
        },
        section01: {
            text: "Trusted by:",
            logos: [
                "/images/home/section01/logo_google.png",
                "/images/home/section01/microsoft-logo-2012@2x.png",
                "/images/home/section01/logo_sumo.png",
                "/images/home/section01/logo_cogni.png",
                "/images/home/section01/logo_bosch.png",
                "/images/home/section01/logo_3m.png",
                "/images/home/section01/logo_lingaro.png",
                "/images/home/section01/logo_livechat.png",
                "/images/home/section01/logo_itmagination.png",
            ],
        },
        section02: {
            text: "Stay active, have fun! Engage teams to win “Best Cycling IT” award for your company.",
            counter: {
                header: "We are starting on August 26th",
                date: "2019-08-26T00:00:00.000Z",
                daysCaption: "days",
                hoursCaption: "hours",
                minutesCaption: "minutes",
            },
        },
        section03: {
            title: "8 weeks edition",
            subtitle: "in 2018",
            cards: [
                {
                    key: "1",
                    color: "#ff244b",
                    backgroundColor: "#ffebeb",
                    captionColor: "#a3747c",
                    icon: "/images/home/section03/1.svg",
                    value: "113 438",
                    caption: "KILOMETERS",
                },
                {
                    key: "2",
                    color: "#6539ff",
                    backgroundColor: "#e5e3ff",
                    captionColor: "#7f74a7",
                    icon: "/images/home/section03/2.svg",
                    value: "46",
                    caption: "teams",
                },
                {
                    key: "3",
                    color: "#ffa437",
                    backgroundColor: "#ffedd7",
                    captionColor: "#a88e6e",
                    icon: "/images/home/section03/3.svg",
                    value: "260",
                    caption: "bikers",
                },
                {
                    key: "4",
                    color: "#3970ff",
                    backgroundColor: "#dcedff",
                    captionColor: "#7180a6",
                    icon: "/images/home/section03/4.svg",
                    value: "12 870",
                    caption: "rides",
                },
            ],
        },
        section04: {
            title: "How it works?",
            text:
                "Bike IT is a global cycling challenge between IT companies. For 8 weeks you will record bike rides to earn points in an engaging mobile game. It’s based on fun and engagement, without sporty race. Top 30% of teams get “Best Cycling Company” award and exclusive 2019 T-shirts. Stay healthy and have fun!",
            cards: [
                {
                    key: "1",
                    title: "Teams",
                    icon: "/images/home/section04/1.svg",
                    text:
                        "Each team has 5 cyclists which cycle in a team league. All members will also compete in individual leaderboard or groups like frontend, backend, CEO, UX.",
                },
                {
                    key: "2",
                    title: "International",
                    icon: "/images/home/section04/2.svg",
                    text:
                        "It’s a first mobile cycling event without borders. We cycle for 8 weeks from any place on the Earth. We are united in a race for health, environment and charity donations.",
                },
                {
                    key: "3",
                    title: "Prizes",
                    icon: "/images/home/section04/3.svg",
                    text:
                        "Beside individual prizes, top 30% of teams will get exclusive Bike IT T-shirts. We also encourage you to sponsor small prizes for your team.",
                },
                {
                    key: "4",
                    title: "Charity",
                    icon: "/images/home/section04/4.svg",
                    text:
                        "This year we united with UNICEF to donate 15% of all tickets to save children's lives, to defend their rights, and to help them fulfill their potential.",
                },
            ],
        },
        section05: {
            left: {
                shirtImage: "/images/home/section05/bikeit-tshirt.png",
                description: "Win limited T-shirts of BikeIT Challenge 2019",
                header: "Can you cycle to top 30% teams?",
            },
            signUpButton: {
                text: "REGISTER",
                link: "/purchase",
            },
            prizes: [
                {
                    image: "https://activyv3test.blob.core.windows.net/prizes/amazon_bikeit.png",
                    text1: "Individually every 2 weeks",
                    text2: "Amazon €25 gift card",
                    text3: "Places: 1-3, 5, 7, <br/> 10, 20, 30, 40, 50",
                },
                {
                    image: "https://activyv3test.blob.core.windows.net/prizes/order_bikeit.png",
                    text1: "Teams every 2 weeks",
                    text2: "Winner Badge of Honor",
                    text3: "Top 5 teams",
                },
                {
                    image: "https://activyv3test.blob.core.windows.net/prizes/BOX_BIKEIT_STRONA.png",
                    text1: "Team General Classification",
                    text2: "Surprise Bicycle Gadgets Package",
                    text3: "Top 5 teams",
                },
            ],
        },
        section06: {
            text1: "Nie przegap okazji",
            text2: "Only **179 €** until 9th of August",
            button: {
                link: "/purchase",
                text: "Sign up a team!",
            },
        },
        section07: {
            title: "ACTIVY - CYCLING GAME APP",
            header:
                "The Bike IT challenge is powered by Activy. It’s not a regular sport app but a motivating cycling game.",
            list: [
                {
                    key: "1",
                    icon: "/images/home/section07/icon-1.svg",
                    text: "collect points for rides, kilometers and streaks",
                },
                {
                    key: "2",
                    icon: "/images/home/section07/icon-2.svg",
                    text: "set your office on map and get bonus points for biking to work",
                },
                {
                    key: "3",
                    icon: "/images/home/section07/icon-3.svg",
                    text: "compete individually and in teams or groups",
                },
                {
                    key: "4",
                    icon: "/images/home/section07/icon-1.svg",
                    text: "hit new levels, earn badges, boost your profile stats",
                },
                {
                    key: "5",
                    icon: "/images/home/section07/icon-2.svg",
                    text: "exchange BikeCoins to special prizes and offers",
                },
            ],
        },
        section08: {
            header: "Meet the Bike IT scoring system",
            footer: "You will find more details about rules in the mobile app.",
            cards: [
                {
                    points: "10",
                    pointsSecondary: "pts",
                    caption: "for each ride above 1,5km",
                },
                {
                    points: "2",
                    pointsSecondary: "pts",
                    caption: "for each kilometer",
                },
                {
                    big: true,
                    points: "up to 900",
                    pointsSecondary: "pts",
                    caption: "streak bonuses",
                },
                {
                    points: "50",
                    pointsSecondary: "pts",
                    caption: "for a ride to/from work",
                },
            ],
            orderMobile: [1, 0, 3, 2],
        },
        section09: {
            quote: "Trusted by small and large companies from IT scene",
            left: {
                headerColor: "#e0e8ff",
                headerTextColor: "#3970ff",
                headerText: "Client",
                quote:
                    "„At Google we support biking to work. Lots of employees choose a bike on everyday basis but the contest was an extra incentive for many others.”",
                authorPrimary: "Peter Zalewski",
                authorSecondary: "Google",
                logo: "/images/home/section09/logo_google.png",
            },
            right: {
                headerColor: "#ebfaed",
                headerTextColor: "#2ecc70",
                headerText: "Client",
                quote:
                    "„We wanted to introduce something up-to-date and attractive for our IT department and the younger generation of employees.”",
                authorPrimary: "Kate Nieroda",
                authorSecondary: "Robert Bosch",
                logo: "/images/home/section09/logo_bosch.png",
            },
        },
        section10: {
            header: "Trusted by",
            images: [
                "/images/home/section10/google-2015-logo.png",
                "/images/home/section10/microsoft-logo-2012.png",
                "/images/home/section10/path-3356_w.png",
                "/images/home/section10/santander-logo.png",
                "/images/home/section10/google-2015-logo.png",
                "/images/home/section10/microsoft-logo-2012.png",
                "/images/home/section10/path-3356_w.png",
                "/images/home/section10/santander-logo.png",
            ],
        },
        section11: {
            cards: [
                {
                    key: "1",
                    caption: "For health and productivity",
                    image: {
                        src: "/images/home/section11/1@350w.jpg",
                        availableSizes: [
                            {
                                src: "/images/home/section11/1@350w.jpg",
                                width: 350,
                            },
                            {
                                src: "/images/home/section11/1@450w.jpg",
                                width: 450,
                            },
                        ],
                    },
                },
                {
                    key: "2",
                    caption: "For prizes and industry recognition",
                    image: {
                        src: "/images/home/section11/2@350w.jpg",
                        availableSizes: [
                            {
                                src: "/images/home/section11/2@350w.jpg",
                                width: 350,
                            },
                            {
                                src: "/images/home/section11/2@450w.jpg",
                                width: 450,
                            },
                        ],
                    },
                },
                {
                    key: "3",
                    caption: "For fun and employee integration",
                    image: {
                        src: "/images/home/section11/3@350w.jpg",
                        availableSizes: [
                            {
                                src: "/images/home/section11/3@350w.jpg",
                                width: 350,
                            },
                            {
                                src: "/images/home/section11/3@450w.jpg",
                                width: 450,
                            },
                        ],
                    },
                },
            ],
        },
        section12: {
            header: "Get started",
            text: "You can register more than one team from your company!",
            chapters: [
                {
                    key: "1",
                    title: "Sign up",
                    content:
                        "1. Register a team on the website\n2. You can pay by credit card or ask for the bank transfer \n3. You will get the invoice by email",
                },
                {
                    key: "2",
                    title: "Prepare your team",
                    content:
                        "1. Choose a name for a team while registering\n2. Share an invitation link or entry code to team members",
                },
                {
                    key: "3",
                    title: "Install the app and join a team",
                    content:
                        "1. Find your team in the leaderboard\n2. Join the team with an entry code \n3. Share an invitation link with other members",
                },
            ],
        },
        section13: {
            text: "Building up teams in a company? Use our promo materials to spread the news!",
            button: {
                link: "https://bikeitchallenge.com/demo/BikeItChallenge_for_employees.zip",
                text: "Download promo materials",
            },
        },
        section14: {
            card: {
                header: "Early bird price!",
                price: "179 €",
                oldPrice: "199 €",
                oldPriceCaption: "Regular price",
                list: [
                    {
                        text: "Online promotion pack",
                    },
                    {
                        text: "Branded team in the leaderboard",
                    },
                    {
                        text: "Leaderboards and team statistics ",
                    },
                    {
                        text: "Customer support 12h/5",
                    },
                    {
                        text: "Donation for UNICEF",
                    },
                ],
                signup: {
                    text: "Join with a *team now!*",
                    button: {
                        text: "REGISTER",
                        link: "/purchase",
                    },
                },
            },
            timer: {
                header: "Hurry up!",
                headerSecondary: "Until 9th of August",
                date: "2019-08-09T15:02:26.708Z",
                daysCaption: "days",
                hoursCaption: "hours",
                minutesCaption: "minutes",
            },
        },
        section15: {
            header: "Do you have any questions?",
            text: "Please write to us.",
            emailPlaceholder: "Enter your e-mail address",
            buttonText: "Send",
            info: "Nie będziemy Cię spamować. Pomożemy włączyć grę Tobie lub Twojej firmie.",
            mailchimp: {
                accountName: "",
                u: "",
                id: "",
            },
        },
        section16: {
            card: {
                title: "Let’s cycle to support UNICEF!",
                text:
                    "## 15% of the ticket price will be donated to projects supporting children\n\n![](/images/home/section16/activy_unicef_logo.png)\n\n_UNICEF does not endorse any company, brand, product or service._",
            },
            logo: "/images/home/section16/activy_unicef_logo.png",
            backgroundImage: {
                desktop: "/images/home/section16/bitmap.jpg",
                mobile: "/images/home/section16/bitmap-mobile.jpg",
            },
        },
    },
    purchase: {
        generalError: "Failed to purchase team. Try again later.",
        sections: {
            billing: {
                fields: {
                    companyAddress: {
                        label: "Company address",
                        placeholder: "Street Name, City, Country",
                    },
                    companyName: {
                        label: "Company name*",
                        placeholder: "Full company name for billing",
                    },
                    vatNumber: {
                        label: "TAX NUMBER",
                        placeholder: "XX123456789",
                    },
                    zipCode: {
                        label: "ZIP CODE",
                        placeholder: "00000",
                    },
                },
                header: "Your company billing information:",
            },
            personal: {
                header: {
                    defaultPrize: "Register a team (179 EUR):",
                    promoPrize: {
                        firstPart: "Register your team ",
                        discountPart: "with {discount}% discount",
                    },
                },
                fields: {
                    email: {
                        label: "YOUR E-MAIL ADDRESS*",
                        placeholder: "email@company.com",
                    },
                    name: {
                        label: "YOUR NAME*",
                        placeholder: "Name and Surname",
                    },
                },
            },
            team: {
                fields: {
                    teamLogo: {
                        label: "YOUR TEAM LOGO",
                        placeholder: "you can set later",
                    },
                    teamName: {
                        label: "YOUR TEAM NAME (INCLUDE COMPANY NAME)*",
                        placeholder: "You can change it later",
                    },
                },
                header: "Your team in the game:",
                uploadLogoButtonLabel: "Upload an image",
            },
            footer: {
                checkoutButtonLabel: "GO TO CHECKOUT",
                header: "You can also contact us directly at contact@bikeitchallenge.com",
            },
        },
    },
    confirmPurchase: {
        checkEmailText: "Please check your email to find all the information and guidance.",
        contactEmail: "contact@bikeitchallenge.com",
        contactText: "If you have any questions contact us on ",
        copyEntryCodeLabel: "COPY",
        downloadAppText: "Download Activy app:",
        entryCodeText: "Your team entry code in Activy app:",
        header: "Welcome to Bike IT Challenge 2019! We wish you happy cycling!",
        teamVisibilityText: "Your team will be visible in the Activy app within 15 minutes",
    },
    payment: {
        contact: "If you prefer to pay by bank transfer please contact us directly on contact@bikeitchallenge.com",
        starterPackText: "Starter pack for 1 team in Bike IT Challenge",
        stripeForm: {
            buttonLabel: "Purchase",
            cardNumberLabel: "Card number",
            expirationDateLabel: "Expiration date",
            securityCodeLabel: "Security code",
            header: "Payment Details (secured by Stripe)",
            errors: {
                card: "Provided card could not be charged.",
                general: "An error during payment occured. Please try again later.",
            },
        },
    },
    common: {
        appStore: {
            link: "https://itunes.apple.com/us/app/activy/id1273195093?ls=1",
            logo: "/images/common/app-store-badge-us-black.svg",
        },
        googlePlay: {
            link: "https://play.google.com/store/apps/details?id=com.activy",
            logo: "/images/common/google-play-badge-us.svg",
        },
    },
    cookie: {
        agreeButton: "I agree",
        text:
            "Our website uses cookies to monitor traffic on our website and ensure that we can provide our customers with the best online experience possible.",
    },
};
export default defaultContent;
