import styled from "styled-components";
import shadowedButton from "styles/shadowed-button";
import themeTransition from "styles/theme-transition";
import color from "color";

export const Button = styled.button.attrs({ type: "submit" })`
    ${p => shadowedButton({ color: p.theme.palette.blue })};
    border: none;
    padding: 1rem 1.5rem;
    color: white;
    width: 100%;
    cursor: pointer;
    transition: all ${themeTransition()};
    :hover {
        background-color: ${p =>
            color(p.theme.palette.blue)
                .darken(0.2)
                .toString()};
    }
    text-transform: uppercase;
    margin-top: 12px;
`;
