import { Button } from "./button";
import React, { useEffect, useState, useContext } from "react";
import { animated, useTransition } from "react-spring/web.cjs";
import styled from "styled-components";
import { ContentContext } from "hooks/use-content";

const Root = styled(animated.div)`
    position: fixed;
    bottom: 1rem;
    left: 1rem;
    right: 1rem;
    background-color: white;
    border-radius: 1rem;
    text-align: center;
    padding: 1rem;
    box-shadow: 0 8px 4px 0 #0002;
    border: 1px solid lightgrey;
`;

const Text = styled.p`
    margin: 16px 0;
`;

const AgreeButton = styled(Button)`
    width: auto;
    text-transform: none;
    margin: 0 0 4px 0;
`;

function Cookie() {
    const [show, setShow] = useState(true);
    const content = useContext(ContentContext).cookie;

    const transition = useTransition(show, null, {
        from: {
            bottom: "-10rem",
            opacity: 0,
        },
        enter: {
            bottom: "1rem",
            opacity: 1,
        },
        leave: {
            bottom: "-10rem",
            opacity: 0,
        },
    });

    useEffect(() => {
        if (show && localStorage.getItem("gdpr-consent") === "true") {
            setShow(false);
        }
        if (!show) {
            localStorage.setItem("gdpr-consent", "true");
        }
    }, [show, setShow]);

    return (
        <>
            {transition.map(
                ({ item: show, props, key }) =>
                    show && (
                        <Root key={key} style={props}>
                            <Text>{content.text}</Text>
                            <AgreeButton onClick={() => setShow(false)}>{content.agreeButton}</AgreeButton>
                        </Root>
                    ),
            )}
        </>
    );
}
export default Cookie;
