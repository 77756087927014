import { createGlobalStyle } from "styled-components";
import { FontFamily, fontsCss } from "./fonts";
import { desktop, mobile } from "./media-queries";
import themeColor from "./theme-color";

const Global = createGlobalStyle`
    ${fontsCss};

    * {
        box-sizing: border-box;
    }

    html {
        font-family: ${FontFamily.Lato}, sans-serif;
        color: ${themeColor("fontDark")};
        --min-gutter-size: 3rem;
        --rem-base: 16;
        @media ${mobile.andDown().toString()} {
            --header-height: 120px;
        }
        @media ${mobile.biggerThanThis().toString()} {
            --header-height: 90px;
        }
        @media ${desktop.smallerThanThis().toString()} {
            --preferred-content-width: 600px;
        }
        @media ${desktop.andUp().toString()} {
            --preferred-content-width: 1000px;
        }
        @media ${mobile.andDown().toString()} {
            overflow-x: hidden;
        }
    }

    body {
        margin: 0;
        padding: 0;
        background-color: ${p => p.theme.palette.backgroundLight};
        ${p => p.theme.palette.fontDark};
        overflow-x: hidden;
    }

    body[data-mobile-menu-open="true"] {
        overflow-y: hidden;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: bold;
    }

    button {
        font-weight: bold;
    }

    /* quick fix for react-slick outlining slides on focus; tabindex="-1" means an element is not focusable anyway */
    *[tabindex="-1"] {
        outline: none;
    }

    a {
        outline: none;
    }

    span {
        line-height: 1;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    hgroup,
    ul,
    ol,
    dd,
    p,
    figure,
    pre,
    table,
    fieldset,
    hr {
        margin-block-start: 0;
        margin-block-end: 1.5rem;
    }
`;

// insert something similar to this for font size dependent on vw; for now not much gain from this except that it makes some elements look bad
// /* https://websemantics.uk/tools/responsive-font-calculator/ */
//     /* 12px @ 325px increasing to 16px @ 1920px */
//     @media (min-width: 325px) {
//         :root {
//             font-size: calc(0.75rem + ((1vw - 3.25px) * 0.2508));
//             /* Where: * 0.2508 = 100 * font_Size_Difference / viewport_Width_Difference */
//         }
//     }
//     /* Prevent font scaling beyond this breakpoint */
//     @media (min-width: 1920px) {
//         :root {
//             font-size: 16px;
//         }
//     }

export default Global;
