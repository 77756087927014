import Color from "color";
import { DynamicImageContent } from "declarations/content";
import { Link } from "gatsby";
import { ContentContext } from "hooks/use-content";
import ActivyLogo from "images/activy-logo.inline.svg";
import cityBackground from "images/footer/city.svg";
import treeLeftBackground from "images/footer/tree-left.svg";
import treeRightBackground from "images/footer/tree-right.svg";
import React, { forwardRef, Ref, useContext } from "react";
import styled from "styled-components";
import { desktop, mobile } from "styles/media-queries";
import themeColor from "styles/theme-color";
import { childSpacing, contentCss, rootCss } from "styles/utils";
import zeplinLerp from "styles/zeplin-lerp";
import ButtonLink from "./button-link";
import DynamicImage from "./dynamic-image";
import Markdown from "./markdown";
import { Badge } from "./badge";

const Root = styled.footer`
    background-color: white;
    ${zeplinLerp("padding-top", 72, 120)};
    ${zeplinLerp("padding-bottom", 180, 290)};

    ${rootCss};

    background-repeat: no-repeat;
    ${zeplinLerp("--bg-city-height", 60, 280)};
    --bg-tree-left-aspect-ratio: calc(140 / 242);
    ${zeplinLerp("--bg-tree-left-height", 120, 242)};
    --bg-tree-left-size: calc(var(--bg-tree-left-aspect-ratio) * var(--bg-tree-left-height)) var(--bg-tree-left-height);

    @media ${mobile.biggerThanThis().toString()} {
        background-image: url(${treeLeftBackground}), url(${treeRightBackground}), url(${cityBackground});
        background-size: var(--bg-tree-left-size), 108px 194px, 100% var(--bg-city-height);
        background-position: left 5.5rem bottom 2rem, right 5.875rem bottom 2.5rem, center bottom 4rem;
    }

    @media ${mobile.andDown().toString()} {
        background-image: url(${treeLeftBackground}), url(${cityBackground});
        background-size: var(--bg-tree-left-size), 100% var(--bg-city-height);
        background-position: left 5.5rem bottom 2rem, center bottom 4rem;
    }
`;

const Content = styled.div`
    ${contentCss};
    display: grid;

    @media ${mobile.biggerThanThis().toString()} {
        --child-height: 3rem;
        --logo-height: var(--child-height);
        grid-template-areas:
            "logo . social demo"
            "text text text text"
            "contact contact address address";
        grid-template-rows: var(--child-height) auto auto;
        grid-template-columns: auto 1fr auto auto;
        row-gap: 1rem;
    }

    @media ${desktop.smallerThanThis().toString()} {
        grid-template-areas:
            "logo . social demo"
            "text text text text"
            "contact contact contact address";
    }

    @media ${mobile.andDown().toString()} {
        --logo-height: 2.5rem;
        grid-template-areas:
            "contact"
            "social"
            "demo"
            "logo"
            "text"
            "address";
        grid-template-rows: auto auto auto auto;
        row-gap: 1.8rem;
        justify-content: center;
        justify-items: center;
    }
`;

const LogoContainer = styled.div`
    grid-area: logo;
`;

const LogoLink = styled(Link)`
    display: inline-block;
    height: 100%;
    width: calc(222.401 / 126.789 * var(--logo-height));
`;

const SocialMedia = styled.div`
    grid-area: social;
    ${childSpacing({ horizontal: "1rem" })};

    @media ${mobile.biggerThanThis().toString()} {
        margin-right: 1.5rem;
    }

    @media ${mobile.andDown().toString()} {
        margin-top: 1rem;
    }
`;

const DemoContainer = styled.div`
    grid-area: demo;
`;

const Text = styled(Markdown)`
    grid-area: text;
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: ${themeColor("fontGrey")};

    @media ${desktop.smallerThanThis().toString()} {
        padding-bottom: 1.5rem;
        border-bottom: 1px solid #d2d8e0;
    }

    @media ${mobile.andDown().toString()} {
        border-bottom: none;
        padding-bottom: 0;
        width: 80%;
        text-align: center;
    }
`;

const Contact = styled(Markdown)`
    grid-area: contact;

    font-weight: bold;
    font-size: 2rem;

    @media ${mobile.andDown().toString()} {
        font-size: 1.5rem;
    }
`;

const Address = styled(Markdown)`
    grid-area: address;
    font-size: 0.875rem;
    color: ${themeColor("fontGrey")};

    p {
        margin: 0.5rem;
    }

    @media ${mobile.andDown().toString()} {
        width: 100%;
        display: flex;
        justify-content: center;
        text-align: center;
    }
`;

const Logo = styled(ActivyLogo).attrs({
    viewBox: "0 0 222.401 126.789",
})`
    width: 100%;
    height: 100%;
    .st0 {
        fill: ${p => p.theme.palette.blue};
    }
` as typeof ActivyLogo;

const SocialMediaButton = (() => {
    const Icon = styled(DynamicImage)`
        width: 100%;
        height: 100%;
        transition: inherit;
    `;

    const Root = styled.a`
        display: inline-block;
        border: 1px solid ${themeColor("veryLightBlue")};
        border-radius: 0.25rem;
        --size: var(--child-height);
        height: var(--size);
        width: var(--size);
        padding: 0.75rem;
        transition: all 0.3s ease;

        @media ${desktop.smallerThanThis().toString()} {
            background-color: ${themeColor("veryLightBlue")};
        }

        @media ${desktop.andUp().toString()} {
            & > ${Icon} {
                filter: grayscale(1);
            }
        }

        :hover {
            background-color: ${themeColor("veryLightBlue")};
            & > ${Icon} {
                filter: none;
            }
        }

        :active {
            background-color: ${p =>
                Color(p.theme.palette.veryLightBlue)
                    .darken(0.1)
                    .toString()};
        }
    `;

    interface SocialMediaButtonProps {
        href: string;
        icon: string | DynamicImageContent;
    }
    return function SocialMediaButton(props: SocialMediaButtonProps) {
        return (
            <Root href={props.href} target="_blank">
                <Icon {...(typeof props.icon === "string" ? { src: props.icon } : props.icon)} />
            </Root>
        );
    };
})();

const DemoButton = styled(ButtonLink)`
    background-color: ${themeColor("orange")};
    box-shadow: 0 8px 24px 0
        ${p =>
            Color(p.theme.palette.orange)
                .alpha(0.15)
                .toString()};
    border-radius: 0.625rem;
    padding: 1rem 1.5rem;
    font-size: 0.875rem;
    line-height: 1rem;
    color: white;
    text-align: center;
    font-weight: bold;
`;

const Badges = styled.div`
    margin-top: 40px;
    display: flex;

    @media ${mobile.andDown().toString()} {
        border-bottom: 1px solid #d2d8e0;
        padding-bottom: 2rem;
        justify-content: center;
    }
`;

const StyledBadge = styled(Badge)`
    height: 40px;

    &:last-of-type {
        margin-left: 40px;
    }
`;

const Footer = forwardRef(function Footer(props: PropsOf<typeof Root>, ref: Ref<HTMLElement>) {
    const content = useContext(ContentContext).footer;
    const commonContent = useContext(ContentContext).common;

    return (
        <Root {...props} ref={ref}>
            <Content>
                <LogoContainer>
                    <LogoLink to="/">
                        <Logo />
                    </LogoLink>
                </LogoContainer>
                <SocialMedia>
                    {content.socialMediaLinks.map(item => (
                        <SocialMediaButton key={item.key} icon={item.icon} href={item.link} />
                    ))}
                </SocialMedia>
                <DemoContainer>
                    <DemoButton to={content.demoButton.link}>{content.demoButton.text}</DemoButton>
                </DemoContainer>
                <Text>{content.text}</Text>
                <Contact>
                    <div>{content.contact}</div>
                    <Badges>
                        <StyledBadge link={commonContent.appStore.link} image={commonContent.appStore.logo} />
                        <StyledBadge link={commonContent.googlePlay.link} image={commonContent.googlePlay.logo} />
                    </Badges>
                </Contact>
                <Address>
                    <Markdown>{content.address}</Markdown>
                </Address>
            </Content>
        </Root>
    );
});
export default Footer;
