import Color from "color";
import { Link } from "gatsby";
import dropProps from "hoc/drop-props";
import { ContentContext } from "hooks/use-content";
import useMediaSSR from "hooks/use-media-ssr";
import useMobileMenuOpenAttr from "hooks/use-mobile-menu-open-attr";
import logoImg from "images/bike-it-logo.png";
import CloseIcon from "images/close.inline.svg";
import MenuIcon from "images/menu.inline.svg";
import React, { MutableRefObject, ReactNode, useContext, useRef, useState } from "react";
import { animated, ReactSpringHook, useChain, useSpring } from "react-spring/web.cjs";
import SSRMQValues from "services/ssr-mq-values";
import styled, { css } from "styled-components";
import { desktop } from "styles/media-queries";
import shadowedButton from "styles/shadowed-button";
import { Theme } from "styles/theme";
import themeColor from "styles/theme-color";
import themeTransition from "styles/theme-transition";
import { childSpacing, contentCss, padding, rootCss } from "styles/utils";
import ButtonLink from "./button-link";
import SvgIconButton from "./svg-icon-button";

const Root = styled.header`
    @media ${desktop.smallerThanThis().toString()} {
        position: absolute;
    }

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;

    ${rootCss};

    height: var(--header-height);
    background-color: white;
    box-shadow: 0 1px 0 0
        ${p =>
            Color(p.theme.palette.fontDark)
                .alpha(0.1)
                .toString()};
`;

const Content = styled.div`
    height: 100%;
    ${padding.y("1rem")};
    ${contentCss};

    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Logo = (() => {
    const LogoWrapper = styled.div`
        height: 100%;
        --logo-aspect-ratio: 197 / 56;
        width: calc((var(--logo-aspect-ratio)) * (var(--header-height) - 2rem));
        max-width: 200px;
    `;
    const LogoLink = styled(Link)`
        display: inline-block;
    `;
    const LogoImage = styled.img`
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: left;
    `;

    return function Logo() {
        return (
            <LogoLink to="/">
                <LogoWrapper>
                    <LogoImage src={logoImg} />
                </LogoWrapper>
            </LogoLink>
        );
    };
})();

const Nav = styled.nav``;

const emphasizedCss = (theme: Theme) => css`
    border-radius: 0.625rem;
    background-color: ${theme.palette.orange};
    box-shadow: 0 8px 24px 0
        ${Color(theme.palette.orange)
            .alpha(0.15)
            .toString()};
    color: white;
`;
const NavLink = styled(ButtonLink)<{ "data-emphasized"?: boolean }>`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-decoration: none;
    color: ${p => p.theme.palette.fontDark};
    padding: 1.25rem 1.5rem;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;

    ${p => p["data-emphasized"] && emphasizedCss(p.theme)};
`;

interface ContentMobileProps {}
const ContentMobile = (() => {
    const Drawer = (() => {
        const Root = styled(dropProps("closed")(animated.div))<{ closed?: boolean }>(
            p => css`
                position: fixed;
                top: 0;
                right: 0;
                width: 0;
                height: 0;
                background-color: white;
                &&& {
                    margin: 0;
                }
            `,
        );

        const ChildrenWrapper = styled(animated.div)`
            height: 100%;
        `;

        interface DrawerProps {
            open: boolean;
            onClose?(): void;
            children?: ReactNode;
        }
        return function Drawer(props: DrawerProps) {
            const spring1Ref = useRef<ReactSpringHook>() as MutableRefObject<ReactSpringHook>;
            const spring1 = useSpring({
                width: props.open ? "100vw" : "0vw",
                height: props.open ? "100vh" : "0vh",
                borderBottomLeftRadius: props.open ? "0vmin" : "100vmin",

                ref: spring1Ref,
                config: {
                    tension: 200,
                },
            });

            const spring2Ref = useRef<ReactSpringHook>() as MutableRefObject<ReactSpringHook>;
            const spring2 = useSpring({
                ref: spring2Ref,
                from: { opacity: 0 },
                to: {
                    opacity: props.open ? 1 : 0,
                },
                config: {
                    tension: 220,
                },
            });

            useChain(props.open ? [spring1Ref, spring2Ref] : [spring2Ref, spring1Ref]);

            return (
                <Root style={spring1} closed={!props.open}>
                    <ChildrenWrapper style={spring2}>{props.children}</ChildrenWrapper>
                </Root>
            );
        };
    })();

    const StyledMenuIcon = styled(MenuIcon)`
        * {
            transition: stroke ${themeTransition("fast")};
            stroke: ${themeColor("fontDark")};
        }
    ` as typeof MenuIcon;

    const MobileNav = styled.nav`
        grid-area: nav;
        ${childSpacing({ vertical: "2rem" })};
    `;

    const MobileNavItem = styled(dropProps("emphasized")(ButtonLink))<{ emphasized?: boolean }>`
        display: block;
        text-align: center;
        font-size: 1.25rem;
        font-weight: bold;
        padding: 1rem 1.5rem;

        ${p =>
            p.emphasized &&
            css`
                ${shadowedButton({ color: p.theme.palette.orange })};
                color: white;
            `};
    `;

    const DrawerContentWrapper = styled.div`
        ${rootCss}
    `;

    const DrawerContent = styled.div`
        ${contentCss};
        display: grid;
        grid-template-areas:
            "logo . close"
            "nav nav nav";
        grid-template-columns: auto 1fr auto;
        grid-template-rows: var(--header-height) auto;
        row-gap: 3rem;
    `;

    const DrawerLogoWrapper = styled.div`
        grid-area: logo;
        align-self: center;
        ${padding.y("1rem")};
    `;

    const CloseButtonWrapper = styled.div`
        grid-area: close;
        align-self: center;
        ${padding.y("1rem")};
    `;

    return function ContentMobile(props: ContentMobileProps) {
        const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
        useMobileMenuOpenAttr(drawerOpen);
        const content = useContext(ContentContext).header;

        return (
            <>
                <Logo />
                <SvgIconButton onClick={() => setDrawerOpen(true)}>
                    <StyledMenuIcon />
                </SvgIconButton>
                <Drawer open={drawerOpen}>
                    <DrawerContentWrapper>
                        <DrawerContent>
                            <DrawerLogoWrapper>
                                <Logo />
                            </DrawerLogoWrapper>
                            <CloseButtonWrapper>
                                <SvgIconButton onClick={() => setDrawerOpen(false)}>
                                    <CloseIcon />
                                </SvgIconButton>
                            </CloseButtonWrapper>
                            <MobileNav>
                                {content.nav.map(item => (
                                    <MobileNavItem key={item.text} emphasized={item.emphasized} link={item.to}>
                                        {item.text}
                                    </MobileNavItem>
                                ))}
                            </MobileNav>
                        </DrawerContent>
                    </DrawerContentWrapper>
                </Drawer>
            </>
        );
    };
})();
interface ContentDesktopProps {}
const ContentDesktop = (() => {
    return function ContentDesktop(props: ContentDesktopProps) {
        const content = useContext(ContentContext).header;
        return (
            <>
                <Logo />
                <Nav>
                    {content.nav.map(item => (
                        <NavLink key={item.text} link={item.to} data-emphasized={item.emphasized}>
                            {item.text}
                        </NavLink>
                    ))}
                </Nav>
            </>
        );
    };
})();

function Header() {
    const isMobile = useMediaSSR(desktop.smallerThanThis().toString(), SSRMQValues);

    return (
        <Root>
            <Content>{isMobile ? <ContentMobile /> : <ContentDesktop />}</Content>
        </Root>
    );
}

Header.propTypes = {};

export default Header;
